// themecolor
$themecolor: #270acc;

$primarybtn:#4256f0;

$white:#fff;

$mate-black:#212121;

$black:#474646;

$gradient-one:#1a0ba4;

$gradient-two:#220990;
