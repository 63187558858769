@use "../../common/breakpoints" as *;
@use "../../common/mixin" as *;
@use "../../common/color" as *;

.true_and_false{
    .inner__headerdash{
        position: relative;
        justify-content: center!important;
    }

    .total__out__leveldata{
        position: absolute;
        left: 0;
        top: 23px;

        @media #{$extra-small-device} {
            position: relative;
            margin-top: 40px;
        }
    }

    .bookmark_btn{
        position: absolute;
        right: 15px;
        top: 75px;
    }
}